import React, {
  ReactNode,
  Fragment,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { HeadFC, Script } from "gatsby";
import { queryResult } from "../data/graphql/query";
import { getGatsbyImageNode } from "../../utils/getGatsbyImageNode";

import LayoutLibrary from "@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-topbar-phone-button";
import ClientAreaButton from "@flyvet/flyvet-react-library/dist/src/components/buttons/client-area-button";

import "../styles/variables.scss";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: any) => {
  const [pageLabel, setPageLabel] = useState("unknown");

  useEffect(() => {
    let path = window.location.pathname;
    if (path === "/") setPageLabel("home");
    else setPageLabel(window.location.pathname);
  }, []);

  const {
    companyName,
    city,
    logoTopbar,
    logoFooter,
    GA4,
    facebook,
    instagram,
    linkedin,
    landingSubtitle,
  } = queryResult();

  const renderButtons = (trackEvent: any) => (
    <ClientAreaButton
      btnLabelTop="Emergência 24H"
      btnLabelBottom="(65) 3359 - 8384"
      clientArealink="tel:+556533598384"
      containerLabel="plantao_button"
      onClick={() => trackEvent({ action: "click_plantao" })}
      variant="outline"
    ></ClientAreaButton>
  );

  return (
    <Fragment>
      {/* <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4}`}
        strategy="off-main-thread"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="off-main-thread"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${GA4}', { send_page_view: false })`,
        }}
      /> */}

      {pageLabel == "/hotel-e-creche" ? (
        <Fragment>
          <script>
            {`(function(window, document, script) {
          if (!window.tt) {
            window.tt = window.tt || {};
              
            c = document.getElementsByTagName('head')[0];
            k = document.createElement('script');
            k.async = 1;
            k.src = script;
            c.appendChild(k);
          }

          window.tt.accountCode = '36f6f52e-14fb-4a10-af66-103967c74229';

        })(window, document, '//s.tintim.app/static/core/tintim-1.0.js');`}
          </script>

          {children}
        </Fragment>
      ) : (
        <Fragment>
          <script>
            {`(function(window, document, script) {
          if (!window.tt) {
              window.tt = window.tt || {};
              
              c = document.getElementsByTagName('head')[0];
              k = document.createElement('script');
              k.async = 1;
              k.src = script;
              c.appendChild(k);
          }
          
          window.tt.accountCode = '0cc685dc-863d-4b02-a14a-e45be8df1476';

        })(window, document, '//s.tintim.app/static/core/tintim-1.0.js');`}
          </script>

          <LayoutLibrary
            logoTopbar={getGatsbyImageNode(
              logoTopbar,
              `Logo ${companyName}`,
              true,
              false,
              false
            )}
            logoFooter={getGatsbyImageNode(
              logoFooter,
              `Logo ${companyName}`,
              true,
              true,
              true
            )}
            renderButton24h={renderButtons}
            phone="556633598384"
            companyName={companyName}
            city={city}
            slogan={landingSubtitle}
            badge={{ type: "vet" }}
            facebook={facebook}
            instagram={instagram.user}
            linkedin={linkedin}
            navItems={[
              {
                label: "Clínica Veterinária",
                path: "/",
              },
              {
                label: "Banho e Tosa",
                path: "/banho-e-tosa",
              },
              {
                label: "Serviços",
                path: "/servicos",
              },
              {
                label: "Galeria",
                path: "/galeria",
              },
              {
                label: "Contato",
                path: "/contato",
              },
            ]}
          >
            {children}
          </LayoutLibrary>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Layout;

export const Head: HeadFC<{}> = ({}) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
);
